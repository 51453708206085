@import "./src/assets/scss/main.scss";





















































































































































































































































































































































































































































































































.form-check-label {
    font-family: Montserrat;
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #000000;
    margin-top: 5px;
}

.form-check-input {
    background: #e7e7e7;
    width: 23px;
    height: 23px;

    &:checked {
        background-color: $orange;
        border-color: $orange;
    }

    &:checked[type="checkbox"] {
        background-image: none;
    }
}

.btn-close {}

.addMenuItem {

    &__img {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__img-add {
        text-align: center;

        input {
            display: none;
        }

        label {
            font-family: Montserrat;
            border-bottom: 1px solid #000;
            font-size: 14px;
            cursor: pointer;
            line-height: 1;
        }
    }

    &__header {
        font-size: 24px;
        line-height: 142.69%;
        margin-bottom: 18px;
    }

    &__subheader {
        font-family: Montserrat;
        font-size: 16px;
    }
}

.addMenuItemInput {
    label {
        display: block;
        font-family: Montserrat;
        font-size: 16px;
        padding-left: 25px;
        margin-bottom: 5px;
    }

    input {
        background: #EBEBEB;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 25px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.form-check {}

.form-check-input {}

.form-check-label {}

.modal-footer {}

.modal-body {
    padding: 33px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 970px;
        margin: 1.75rem auto;
    }

}

.maw169 {
    max-width: 169px;
}

.brown {
    background: #3F2A2C !important;
    color: #fff !important;
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
}

.maw403 {
    max-width: 403px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.available-time {
    input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 2px solid #f5f5f5;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        padding-left: 33px;
        padding-right: 33px;
        cursor: pointer;
        background: #f5f5f5;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }
}

.invalid-feedback {
    padding-left: 25px;
}

.brown {
    border-radius: 73px !important;
    background: #3F2A2C !important;
    overflow: hidden;

    input {
        padding-top: 150px !important;
        padding-bottom: 105px !important;
        height: 100px !important;
        border: none !important;
        height: 100px !important;

    }
}

.form-select {
    width: 120px;
    height: 50px;
    border-radius: 50px;
    background: #EBEBEB;
    border-color: #EBEBEB;
}

.available-time-header--low {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    color: #000000;
}

.addMenuItem__img {
    img {
        border-radius: 12px;
    }
}

.food-item__noimg {
    padding-top: 82%;
    width: 100%;
    background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
    border-radius: 12px;
}

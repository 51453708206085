@import "./src/assets/scss/main.scss";




































    .colors__items {
    input[type=radio] {
        padding: 13px 17px;
        appearance: none;
        opacity: .2;
        border-radius: 5px;
        margin-right: 5px;
         cursor: pointer !important;

    }

    input[type=radio]:checked {
        display: inline-block;
        opacity: 1;
         cursor: pointer !important;
    }
    label{
            cursor: pointer !important;
        }
}
.c11 {
    background: #000;
}

.c12 {
    background: #FF7728;
}

.c13 {
    background: #0041E8;
}

.c14 {
    background: #CD006F;
}

.c15 {
    background: #008E1F;
}

.c16 {
    background: #B9002C;
}

.c17 {
    background: #00748D;
}

.c18 {
    background: #898C00;
}

.c19 {
    background: #9100EA;
}


@import "./src/assets/scss/main.scss";

























































.navbar-vertical {
    width: 244px;
    text-align: center;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 8px;
        background: #EBEBEB;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */
        opacity: .5;
        color: #fff;
        &:hover{
            color:#fff;
        }

    }
}

.router-link-exact-active {
    background: $orange;
    color: #fff;
}

.editColor {
     display: inline-block;
    font-family: Montserrat;
    color: #000000;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
margin-top: 10px;
    border-bottom: 1px solid #000;
    &:hover{
        color: #000000;
    }
}

.active {
    opacity: 1;
}
.activeBtn{
    opacity: 0.5;
}

@import "./src/assets/scss/main.scss";




















































.food-item {
    background: #F5F5F5;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    margin-bottom: 5px;
    max-height: 460px;
    position: relative;
}

.food-item__img {
     max-height: 255px;
     overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}

.food-item__meta {
    padding: 15px 20px;
}

.food-item__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 9px;
}

.food-item__weight {
    font-size: 14px;
    line-height: 17px;
    font-family: Montserrat;
    margin-bottom: 10px;

    align-items: center;

    color: #828282;
    i{
        position: relative;
        bottom: 2px;
        width: 4px;
        height: 4px;
        display: inline-block;
        background: #828282;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;

    }
}

.food-item__desc {
    font-family: Montserrat;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 17px;
}

.food-item__price {
    color: $orange;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    white-space: nowrap;
    text-align: right;

    span {

        // display: block;
        font-size: 24px;

    }
}

.food-item__add-cart {}

.btn-orange-middle {
    @extend .btn;
    background: $orange;
    white-space: nowrap;
    border-radius: 12px;
    font-weight: 600;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    padding: 14px 22px;
}

.food-item__color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #000;
}
.food-item__footer{
    position: absolute;
    left: 15px;
   padding-right: 30px;
    width: 100%;
    bottom: 20px;
}
.food-item__noimg{
    padding-top: 82%;
    width: 100%;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
      border-radius: 12px 12px 0 0;
}

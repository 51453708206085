@import "./src/assets/scss/main.scss";





































































.admin-header {
    font-size: 40px;
    line-height: 100.74%;
}

.cabinet {
    margin-top: 35px;
    padding-bottom: 80px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
position: relative;
    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;

    }

}
.contacts{
    position: relative;
}


@import "./src/assets/scss/main.scss";



















































.modal-body {
    padding: 18px 10px 0 82px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 970px;
        margin: 1.75rem auto;
    }

}

.colors-header {
    font-size: 24px;
    text-align: center;
    margin-top: 45px;
    padding-bottom: 10px;
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
}

.maw403 {
    max-width: 403px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.colors {
    margin-bottom: 36px;

    &__header {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
    }

    &__items {
        margin-top: 9px;
        
    }
} 

